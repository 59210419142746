"use client";
import ErrorContent from "@/Core/Shared/500/ErrorContent";
import Cookies from "js-cookie";

const Error500 = () => {
  const lang = Cookies.get("language") ?? "en";
  return <ErrorContent lang={lang} />;
};

export default Error500;
