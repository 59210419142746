import "@/app/assets/css/not-found.css";
import NotFoundImg from "@/app/assets/images/threads.svg";
import Link from "next/link";
import Image from "next/image";
import t from "@/app/hooks/lang";

interface Props {
  lang: string;
  title?: string;
  message?: string;
}

const ErrorContent = ({ lang, title, message }: Props) => {
  return (
    <div className="not-found error-margin">
      <Image src={NotFoundImg} alt="Not Found" />
      <h1>{t(title ?? "500ErrorTitle", lang)}</h1>
      <p>{t(message ?? "500ErrorBody", lang)}</p>
      <Link href="/">
        <button className="recovo-btn-black">{t("goHomepage", lang)}</button>
      </Link>
    </div>
  );
};

export default ErrorContent;
